import { cx } from '@emotion/css';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

import {
  MemoizedNotificationsDrawer,
  MOBILE_MEDIA_QUERY,
  NotificationsIcon,
  selectCurrentUserState,
  selectNotificationsUnreadCount,
  useAppSelector,
} from '@xq/web-components';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

import {
  StyledActionsContainer,
  StyledBadge,
  StyledImage,
  StyledNotificationIconWrapper,
  StyledUserAvatar,
} from './HeaderActions.style';
import { StudentDropdownMenu } from '../StudentDropdownMenu/StudentDropdownMenu';

export const HeaderActions = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const currentUser = useAppSelector(selectCurrentUserState);
  const unreadCount = useAppSelector(selectNotificationsUnreadCount);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  const openDropdownMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <StyledActionsContainer>
      <StyledBadge badgeContent={unreadCount} color="secondary" max={999}>
        <StyledNotificationIconWrapper
          className={cx('icon-background', {
            'active-background': openDrawer,
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setOpenDrawer(true);
          }}
          onClick={(e) => {
            const targetType = (e.target as { type?: string })?.type;
            if (!targetType) setOpenDrawer(true);
          }}
        >
          <NotificationsIcon />
        </StyledNotificationIconWrapper>
      </StyledBadge>
      <MemoizedNotificationsDrawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        drawerAnchor="right"
        paths={{
          ...STUDENT_APP_ROUTES,
          getJournal: () =>
            isMobile
              ? (STUDENT_APP_ROUTES.getJournalFeed?.() as string)
              : (STUDENT_APP_ROUTES.getJournalPhase?.(
                  '0',
                  '0',
                  '0',
                  '0'
                ) as string),
        }}
      />
      <StyledUserAvatar
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={openDropdownMenu}
        size="large"
        id="user-account-menu"
        data-cy="user-profile"
      >
        <StyledImage
          src={currentUser?.photoUrl || ''}
          alt={t('user_profile_photo')}
        ></StyledImage>
      </StyledUserAvatar>
      <StudentDropdownMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </StyledActionsContainer>
  );
};
