import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ConstructionIcon from '@mui/icons-material/Construction';
import HelpIcon from '@mui/icons-material/Help';
import { useTour } from '@reactour/tour';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';

import {
  selectCurrentUserState,
  useAppSelector,
  DropDownMenu,
  useApi,
  useModal,
  SystemCheckModalBody,
  MOBILE_MEDIA_QUERY,
} from '@xq/web-components';
import { STUDENT_APP_ROUTES } from '@xq/web-config';
import { UserRepository } from '@xq/infrastructure';
import { shouldUpdateLastActivity } from '@xq/domain';

type UserDropdownMenuProps = {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
};

export const StudentDropdownMenu = ({
  anchorEl,
  setAnchorEl,
}: UserDropdownMenuProps) => {
  const { t } = useTranslation();
  const { logout } = useApi();
  const { setIsOpen, setCurrentStep } = useTour();
  const currentUser = useAppSelector(selectCurrentUserState);
  const { dispatchModal, onConfirm } = useModal();

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const navigate = useNavigate();

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const startDiagnostic = () => {
    dispatchModal({
      hideCancel: true,
      resetOnConfirm: false,
      titleText: t('checking_system'),
      bodyText: <SystemCheckModalBody />,
      showActionButton: true,
      actionButtonText: t('continue'),
      showIcon: false,
      isOpen: true,
      width: 'min(100vw, 600px)',
    });
  };
  const handleLogOut = async () => {
    if (shouldUpdateLastActivity(currentUser)) {
      const userRepository = new UserRepository();
      await userRepository.updateLastActivity(currentUser.uid);
    }
    await logout();
  };
  return (
    <DropDownMenu.StyledMenu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DropDownMenu.StyledMenuItem onClick={closeMenu}>
        <DropDownMenu.StyledLink to={STUDENT_APP_ROUTES.getYourClassrooms()}>
          <CorporateFareIcon fontSize="small" />
          {t('your_classrooms')}
        </DropDownMenu.StyledLink>
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledSeparator />

      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledAnchorTag
          target="_blank"
          rel="noopener"
          href={STUDENT_APP_ROUTES.getTermsOfService()}
        >
          <DescriptionIcon fontSize="small" />
          {t('terms_of_service')}
        </DropDownMenu.StyledAnchorTag>
      </DropDownMenu.StyledMenuItem>
      <DropDownMenu.StyledMenuItem>
        <DropDownMenu.StyledAnchorTag
          target="_blank"
          rel="noopener"
          href={STUDENT_APP_ROUTES.getPrivacyPolicy()}
        >
          <LockIcon fontSize="small" />
          {t('privacy_policy')}
        </DropDownMenu.StyledAnchorTag>
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledMenuItem
        onClick={() => {
          onConfirm.current = () => startDiagnostic();
          dispatchModal({
            hideCancel: true,
            resetOnConfirm: false,
            titleText: t('system_diagnostic_title'),
            bodyText: t('system_diagnostic_info'),
            showActionButton: true,
            actionButtonText: t('start_diagnostic'),
            showIcon: false,
            isOpen: true,
            width: 'min(100vw, 700px)',
          });
        }}
      >
        <ConstructionIcon fontSize="small" />
        {t('system_diagnostic')}
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledMenuItem
        onClick={() => {
          if (isMobile) {
            navigate(STUDENT_APP_ROUTES.getMobileWizardGuide());
            return;
          }

          setCurrentStep(0);
          setIsOpen(true);
          closeMenu();
          navigate(STUDENT_APP_ROUTES.getJournalPhase('0', '0', '-1', '-1'));
        }}
      >
        <HelpIcon fontSize="small" />
        {t('user_guide')}
      </DropDownMenu.StyledMenuItem>

      <DropDownMenu.StyledMenuItem
        data-cy="student-log-out"
        onClick={handleLogOut}
      >
        <PowerSettingsNewIcon fontSize="small" />
        {t('log_out')}
      </DropDownMenu.StyledMenuItem>
      <DropDownMenu.StyledSeparator />
      <DropDownMenu.StyledMenuItem
        style={{ paddingLeft: '8px', paddingBottom: '0px' }}
      >
        <DropDownMenu.StyledUserImage
          src={currentUser?.photoUrl || ''}
          alt={t('user_profile_photo')}
        />
        <div>
          <DropDownMenu.StyledTruncateData>
            {currentUser?.displayName}
          </DropDownMenu.StyledTruncateData>
          <DropDownMenu.StyledTruncateData>
            {currentUser?.email}
          </DropDownMenu.StyledTruncateData>
        </div>
      </DropDownMenu.StyledMenuItem>

      {!isMobile && (
        <DropDownMenu.StyledMenuItem
          style={{ paddingLeft: '8px', paddingBottom: '0px' }}
        ></DropDownMenu.StyledMenuItem>
      )}
    </DropDownMenu.StyledMenu>
  );
};
