import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
  selectCurrentUserState,
  persistCurrentClassroomId,
  selectCurrentUserClassrooms,
  MOBILE_MEDIA_QUERY,
  useSnackbarNotifier,
  Modal,
  selectIsImpersonating,
  selectVisibleBanners,
  fetchWordPressTemplates,
  selectIsFullscreen,
  appActions,
  Banner,
  logAnalyticsEventOnTitleChange,
  selectCurrentUserClassroom,
  selectCurrentUserSchool,
  WizardGuide,
  useStudentWizardGuide,
} from '@xq/web-components';

import { TopBar } from './components/TopBar/TopBar';
import { MobileHeader } from './components/MobileHeader/MobileHeader';
import { FabAppMenu } from './components/FabAppMenu/FabAppMenu';
import { StyledAppContainer } from './App.styles';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

const VIEWER_BANNER_COLOR = 'var(--mamba)';

export function App() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const classrooms = useAppSelector(selectCurrentUserClassrooms);
  const currentUser = useAppSelector(selectCurrentUserState);
  const currentClassroom = useAppSelector(selectCurrentUserClassroom);
  const currentSchool = useAppSelector(selectCurrentUserSchool);

  useSnackbarNotifier();

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const visibleBanners = useAppSelector(selectVisibleBanners);
  const isImpersonating = useAppSelector(selectIsImpersonating);
  const isFullscreen = useAppSelector(selectIsFullscreen);

  const { steps, onStepChange, STUDENT_WIZARD_GUIDE_STEPS } =
    useStudentWizardGuide();

  const location = useLocation();

  const appHeight = useMemo(() => {
    const navBarHeight = isFullscreen ? '0px' : '56px';
    const singleBannerHeight = 42;
    const defaultHeight = '(var(--vh) * 100)';
    const visibleBannersCount =
      Object.values(visibleBanners).filter(Boolean).length;
    const scopeBannerHeight = !visibleBannersCount
      ? '0px'
      : `${visibleBannersCount * singleBannerHeight}px`;

    return `calc(${defaultHeight} - ${navBarHeight} - (${scopeBannerHeight}))`;
  }, [isFullscreen, visibleBanners]);

  useEffect(() => {
    const classroomExists = classrooms?.find(
      (c) => c.id === currentUser.currentClassroomId
    );

    if (!classroomExists && classrooms?.length) {
      dispatch(
        persistCurrentClassroomId({
          userId: currentUser.uid,
          currentClassroomId: classrooms[0].id,
        })
      );
    }
  }, [classrooms, currentUser.currentClassroomId]);

  useEffect(() => {
    const promise = dispatch(fetchWordPressTemplates());
    return () => promise.abort();
  }, []);

  useEffect(() => {
    if (!currentClassroom || !currentSchool) return;

    logAnalyticsEventOnTitleChange({
      classroomName: currentClassroom.name,
      schoolName: currentSchool.name,
    });
  }, [currentClassroom, currentSchool]);

  const isViewingMobileWizardGuide =
    location.pathname === STUDENT_APP_ROUTES.getMobileWizardGuide();

  const header = useMemo(() => {
    if (isViewingMobileWizardGuide) {
      return null;
    }

    return isMobile ? <MobileHeader /> : <TopBar />;
  }, [isMobile, location.pathname]);

  return (
    <StyledAppContainer>
      <Modal />
      <WizardGuide
        steps={steps}
        onStepChange={onStepChange}
        STUDENT_WIZARD_GUIDE_STEPS={STUDENT_WIZARD_GUIDE_STEPS}
      >
        <Banner
          text={t('demo_user_banner_message', {
            demoUserName: currentUser.displayName,
          })}
          onClose={() => {
            dispatch(
              appActions.setBannersVisibility({
                demo: false,
              })
            );
          }}
          backgroundColor={VIEWER_BANNER_COLOR}
          isHidden={!isImpersonating || !visibleBanners.demo}
        />
        {header}
        <Grid
          container
          justifyContent="center"
          style={{ height: appHeight, overflow: 'hidden' }}
        >
          <Outlet />
        </Grid>
        {!isViewingMobileWizardGuide && <FabAppMenu />}
      </WizardGuide>
    </StyledAppContainer>
  );
}

export default App;
