import { Typography, Button } from '@mui/material';
import { StepType, TourProvider } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from 'react';
import { StudentWizardStep } from '../hooks';

type WizardGuideProps = {
  steps: StepType[];
  onStepChange: (stepSlug: StudentWizardStep) => Promise<void>;
  STUDENT_WIZARD_GUIDE_STEPS: StudentWizardStep[];
};

export const WizardGuide = ({
  steps,
  children,
  onStepChange,
  STUDENT_WIZARD_GUIDE_STEPS,
}: PropsWithChildren<WizardGuideProps>) => {
  const { t } = useTranslation();

  if (!steps.length) {
    return <>{children}</>
  }

  return (
    <TourProvider
      showDots={false}
      showBadge={false}
      components={{
        Content: ({ content }) => (
          <div>
            <Typography variant="overline">{t('onboarding')}</Typography>
            <div
              style={{ color: 'var(--wizard-guide-body-text-color)' }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        ),
      }}
      prevButton={({ currentStep, setCurrentStep, steps }) => {
        const first = currentStep === 0;

        return (
          <>
            {t('step')} {currentStep + 1} {t('of')} {steps?.length}
            {!first && (
              <Button
                variant="outlined"
                color="inherit"
                onClick={async () => {
                  await onStepChange(
                    STUDENT_WIZARD_GUIDE_STEPS[currentStep - 1]
                  );
                  if (first && steps?.length) {
                    setCurrentStep(() => steps.length - 1);
                  } else {
                    setCurrentStep((s) => s - 1);
                  }
                }}
              >
                {t('back')}
              </Button>
            )}
          </>
        );
      }}
      nextButton={({
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1;
        return (
          <Button
            variant="contained"
            onClick={async () => {
              await onStepChange(STUDENT_WIZARD_GUIDE_STEPS[currentStep + 1]);
              if (last) {
                setIsOpen(false);
              } else if (steps?.length) {
                setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
              }
            }}
          >
            {last ? t('close') : t('next')}
          </Button>
        );
      }}
      steps={steps}
      padding={{ popover: 20 }}
    >
      {children}
    </TourProvider>
  );
};
