import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { useMediaQuery } from '@mui/material';

import {
  fetchUserProgramProgress,
  MemoizedJournal,
  PhaseContextProvider,
  Phases,
  selectCurrentUserClassroom,
  selectCurrentUserProgram,
  selectCurrentUserState,
  selectProgramPost,
  selectUserProgramProgress,
  useAppDispatch,
  useAppSelector,
  useMobile,
  MOBILE_MEDIA_QUERY,
  useNavigateToActivePhase,
} from '@xq/web-components';
import { getActivePhaseIndex } from '@xq/domain';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

import styles from './phases-and-journal.module.scss';
import { JournalTab } from '@xq/web-components/Journal/JournalTabs';

export type PhasesAndJournalProps = {
  renderOnlyPhases?: boolean;
};

const PhasesAndJournal = ({
  renderOnlyPhases = false,
}: PhasesAndJournalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { phaseIndex } = useParams();
  const dispatch = useAppDispatch();
  const { setHeaderContent } = useMobile();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const [isJournalCollapsed, setIsJournalCollapsed] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(JournalTab.MyJournal);

  const currentProgram = useAppSelector(selectCurrentUserProgram);
  const classroom = useAppSelector(selectCurrentUserClassroom);
  const currentUser = useAppSelector(selectCurrentUserState);
  const programPost = useAppSelector(selectProgramPost);
  const userProgramProgress = useAppSelector((state) =>
    selectUserProgramProgress(
      state,
      currentUser.uid,
      classroom?.id ?? '',
      currentProgram?.id ?? ''
    )
  );

  const activePhaseIndex = useMemo(() => {
    return getActivePhaseIndex(programPost, userProgramProgress);
  }, [programPost, userProgramProgress]);

  const navigateToActivePhase = () => {
    navigate(
      STUDENT_APP_ROUTES.getJournalPhase(
        activePhaseIndex.toString(),
        '0',
        '-1',
        '-1'
      )
    );
  };

  useNavigateToActivePhase();

  useEffect(() => {
    if (currentProgram && classroom?.id && programPost) {
      dispatch(
        fetchUserProgramProgress({
          slug: currentProgram.slug,
          classroomId: classroom.id,
          currentPhaseId: programPost.studentPhaseValues?.[0]?.id ?? 0,
          userId: currentUser.uid,
          programId: currentProgram.id,
        })
      );
    }
  }, [currentProgram, classroom?.id, currentUser.uid, programPost]);

  // We want to set the activePhaseIndex when phaseIndex different than 0, to prevent activePhaseIndex messing with the
  // user navigation when for example entering the page from back navigation
  useEffect(() => {
    if (
      isUndefined(phaseIndex) ||
      (!phaseIndex && +phaseIndex !== activePhaseIndex)
    ) {
      navigateToActivePhase();
    }
  }, [activePhaseIndex, phaseIndex]);

  useEffect(() => {
    if (isMobile) {
      setHeaderContent(t('resources'));
    }
  }, [isMobile]);

  const renderPhases = () => (
    <PhaseContextProvider
      resourcePath={STUDENT_APP_ROUTES.getResourceContent}
      phasePath={STUDENT_APP_ROUTES.getJournalPhase}
      activePhaseIndex={activePhaseIndex}
    >
      <Phases
        isJournalCollapsed={isJournalCollapsed}
        setActiveTabIndex={setActiveTabIndex}
      />
    </PhaseContextProvider>
  );

  if (renderOnlyPhases) {
    return <div className={styles.journalContainer}>{renderPhases()}</div>;
  }

  return (
    <>
      {renderPhases()}
      <MemoizedJournal
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        setIsJournalCollapsed={setIsJournalCollapsed}
        isJournalCollapsed={isJournalCollapsed}
        currentStudent={currentUser}
      />
    </>
  );
};

export default PhasesAndJournal;
