import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from 'react';

export type MobileState = {
  headerContent: ReactNode | undefined;
  setHeaderContent: (content: ReactNode) => void;
  onlyHeaderContent: boolean;
  setOnlyHeaderContent: (onlyHeaderContent: boolean) => void;
};

const MobileContext = createContext<MobileState | undefined>(undefined);

export const MobileProvider = ({ children }: PropsWithChildren) => {
  const [headerContent, setHeaderContent] = useState<ReactNode | undefined>(
    undefined
  );
  const [onlyHeaderContent, setOnlyHeaderContent] = useState(false);

  return (
    <MobileContext.Provider
      value={{
        headerContent,
        setHeaderContent,
        onlyHeaderContent,
        setOnlyHeaderContent,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = () => {
  const context = useContext(MobileContext);

  if (!context) {
    throw new Error('useMobile  must be used within a MobileProvider');
  }

  return context;
};
