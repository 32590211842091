import type { StyledComponent } from '@emotion/styled';
import { MenuItem, Select, SelectProps, styled } from '@mui/material';

export const StyledSelect = styled(Select)`
  color: var(--white);
  background: var(--mulled-wine);
  margin: 10px;
  border-radius: 0;

  &.Mui-focused {
    .MuiSelect-select,
    .MuiSvgIcon-root {
      color: var(--white);
    }
  }

  .MuiSelect-select {
    padding: 10px;
  }

  fieldset {
    border: none;
  }

  .MuiSvgIcon-root {
    color: var(--grey-text);
  }
` as StyledComponent<SelectProps<string>>;

export const StyledMenuItem = styled(MenuItem)`
  color: var(--white);

  &.Mui-selected {
    background: var(--green-primary);
    color: var(--white);

    &.Mui-focusVisible {
      background-color: rgba(62, 182, 80, 0.7);
    }

    &:hover {
      background: rgba(62, 182, 80, 0.8);
    }
  }

  &.Mui-focusVisible {
    background-color: rgba(62, 182, 80, 0.3);
  }

  &:hover {
    background-color: rgba(62, 182, 80, 0.5);
    color: var(--white);
  }
`;
