import { useLocation } from 'react-router-dom';

import {
  NavigationTabs,
  XqIconPrimaryColor,
  PathLabel,
  useAppSelector,
  selectCurrentUserClassrooms,
  selectIsFullscreen,
} from '@xq/web-components';

import { STUDENT_APP_ROUTES } from '@xq/web-config';

import {
  StyledAppBar,
  StyledContainer,
  StyledDivider,
  StyledTabsContainer,
  StyledTopBar,
  StyleLogoBtn,
} from './TopBar.styles';
import { ReactComponent as Journal } from '../../../assets/tab-icons/journal.svg';
import { NavClassroomSelect } from '../NavClassroomSelect/NavClassroomSelect';
import { checkPaths } from '../../utils/checkPaths';
import { HeaderActions } from '../HeaderActions/HeaderActions';

export const TopBar = () => {
  const classrooms = useAppSelector(selectCurrentUserClassrooms);
  const isFullscreen = useAppSelector(selectIsFullscreen);

  const tabRoutes: PathLabel[] = [
    {
      label: 'project_journal',
      path: STUDENT_APP_ROUTES.getJournalPath(),
    },
  ];

  const location = useLocation();

  if (isFullscreen) return null;

  return (
    <StyledTopBar>
      <StyledAppBar position="static">
        <StyledContainer>
          <StyleLogoBtn
            aria-label="xqLogoButton"
            edge="start"
            color="inherit"
            size="large"
          >
            <XqIconPrimaryColor fontSize={'large'} color={'primary'} />
          </StyleLogoBtn>

          {classrooms?.length > 1 && (
            <StyledDivider orientation="vertical" flexItem />
          )}

          <NavClassroomSelect />
        </StyledContainer>

        {!checkPaths(location.pathname) ? (
          <StyledTabsContainer>
            <NavigationTabs
              routes={tabRoutes}
              icons={[Journal]}
            />
          </StyledTabsContainer>
        ) : null}

        <HeaderActions />
      </StyledAppBar>
    </StyledTopBar>
  );
};
