import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { STUDENT_APP_ROUTES } from '@xq/web-config';
import { MobileModalBody, SimpleModal } from '@xq/web-components';

import { ReactComponent as JournalIcon } from '../../../assets/journal.svg';
import { StyledModalMenu } from '../../App.styles';
import { checkPaths } from '../../utils/checkPaths';
import { MobileFab } from '../MobileFab/MobileFab';

export const FabAppMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isFabModalOpen, setIsFabModalOpen] = useState<boolean>(false);

  const handleClick = (path: string) => {
    navigate(path);
    setIsFabModalOpen(false);
  };

  const path = location.pathname;

  const menuItems = [
    {
      itemName: t('my_journal'),
      itemIcon: <JournalIcon />,
      onClick: () => {
        handleClick(STUDENT_APP_ROUTES.getPostToJournal());
      },
    },
    {
      itemName: t('my_files'),
      itemIcon: <AttachFileIcon />,
      onClick: () => {
        handleClick(STUDENT_APP_ROUTES.getAddFiles());
      },
    },
  ];
  const menuHeader = {
    itemName: t('post_to'),
    itemIcon: <CloseOutlinedIcon onClick={() => setIsFabModalOpen(false)} />,
    onClick: () => setIsFabModalOpen(false),
  };

  const openModal = () => {
    setIsFabModalOpen(true);
  };

  return (
    <>
      {checkPaths(path) ? null : <MobileFab openModal={openModal} />}
      <SimpleModal
        open={isFabModalOpen}
        onClose={() => setIsFabModalOpen(false)}
      >
        <StyledModalMenu>
          <MobileModalBody menuItems={menuItems} menuHeader={menuHeader} />
        </StyledModalMenu>
      </SimpleModal>
    </>
  );
};
