import { AppBar, Divider, IconButton, styled } from '@mui/material';

export const StyledTopBar = styled('div')`
  position: fixed;
  bottom: 0;
  flex-wrap: initial;
  width: 100%;
  z-index: 1000;
  height: var(--top-bar-height);

  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  .icon-background {
    background: var(--white);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    svg {
      opacity: 0.5;

      &:hover {
        opacity: 1;
        fill: var(--white);
      }
    }
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
  }

  .MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 56px;
  }

  .active-background {
    background: var(--green-primary);

    img {
      opacity: 1;
    }
  }

  @media screen and (min-width: 640px) {
    position: relative;

    .icon-background {
      background: var(--nav-icon-background-color);
    }

    .MuiTabs-flexContainer {
      justify-content: unset;
    }
  }
`;

export const StyledAppBar = styled(AppBar)`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  background-color: var(--student-nav-background-color);
  height: var(--top-bar-height);

  @media screen and (min-width: 1025px) {
    grid-template-columns: 400px minmax(max-content, 1fr) minmax(
        max-content,
        400px
      );
  }
`;

export const StyleLogoBtn = styled(IconButton)(({ theme }) => ({
  display: 'none',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),

  '@media (min-width: 1025px)': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StyledContainer = styled('div')`
  display: grid;
  grid-template-columns: minmax(max-content, 184px);

  @media screen and (min-width: 1025px) {
    grid-template-columns: repeat(2, max-content) 1fr;
  }

  .MuiInputBase-root {
    height: 36px;
  }
`;

export const StyledTabsContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const StyledDivider = styled(Divider)`
  display: none;
  margin: 10px 0;
  background: var(--grey-text);
  width: 2px;

  @media (min-width: 1025px) {
    display: block;
  },
`;
