import { styled } from '@mui/material';

export const StyledAppContainer = styled('div')`
  color: var(--grey-dark);
`;

export const StyledModalMenu = styled('div')`
  position: fixed;
  right: 30px;
  bottom: 30vh;
  background-color: var(--white);
`;

export const StyledBanner = styled('div')`
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
`;
