import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material';

export const StyledFabContainer = styled('div')`
  position: fixed;
  right: -33px;
  bottom: 40vh;
  z-index: 99;

  @media screen and (min-width: 640px) {
    display: none;
  }
`;

const StyledFab = styled('div')`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var(--green-primary);
`;

const StyledEditIcon = styled(EditIcon)`
  color: white;
  position: relative;
  top: 16px;
  left: 3px;
`;

interface MobileFabProps {
  openModal: () => void;
}

export const MobileFab = ({ openModal }: MobileFabProps) => {
  return (
    <StyledFabContainer>
      <StyledFab color="primary" aria-label="edit">
        <StyledEditIcon fontSize={'small'} onClick={openModal} />
      </StyledFab>
    </StyledFabContainer>
  );
};
