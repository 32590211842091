import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useHash } from 'react-use';

import { Document } from '@xq/domain';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

import { useNotifier } from '../context';
import { MarkForReview } from '../MarkForReview/MarkForReview';
import {
  selectCurrentUserClassroomId,
  selectCurrentUserProgramId,
  selectCurrentUserState,
  selectUserDocumentById,
  toggleMarkForReview,
  useAppDispatch,
  useAppSelector,
} from '../store';
import {
  StyledDocumentViewer,
  StyledActionsHeader,
  StyledContainer,
} from './DocumentViewer.styles';
import { useStudentsFilesSubscription } from '../hooks';
import { MY_FILES_PATH } from '../utils';

export const DocumentViewer = () => {
  const { documentId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { notifyTeachersForDocumentReview } = useNotifier();

  const currentUser = useAppSelector(selectCurrentUserState);

  const currentClassroomId = useAppSelector(selectCurrentUserClassroomId);

  const currentProgramId = useAppSelector(selectCurrentUserProgramId);

  const [, updateHash] = useHash();

  useStudentsFilesSubscription({
    userIds: [currentUser.uid],
    programId: currentProgramId ?? '',
    classroomId: currentClassroomId ?? '',
  });

  const viewedDocument = useAppSelector((documents) =>
    selectUserDocumentById(
      documents,
      currentUser.uid,
      currentClassroomId ?? '',
      currentProgramId ?? '',
      documentId ?? ''
    )
  );

  const dispatch = useAppDispatch();

  const handleMarkForReview = async (document: Document) => {
    if (currentClassroomId && currentProgramId && documentId) {
      const isForReview = !document.properties?.isForReview;

      await dispatch(
        toggleMarkForReview({
          fileId: documentId,
          classroomId: currentClassroomId,
          isForReview,
          programId: currentProgramId,
          userId: currentUser.uid,
        })
      );

      return notifyTeachersForDocumentReview(document.name, isForReview);
    }
  };

  const goToMyFiles = () => {
    navigate(STUDENT_APP_ROUTES.getJournalPhase('0', '0', '-1', '-1'));
    updateHash(MY_FILES_PATH);
  };

  useEffect(() => {
    if (!viewedDocument) goToMyFiles();
  }, [viewedDocument]);

  if (!viewedDocument) return null;

  return (
    <StyledContainer>
      <StyledActionsHeader>
        <Button
          color="inherit"
          onClick={() => {
            goToMyFiles();
          }}
          startIcon={<ArrowBackIcon />}
        >
          {t('back')}
        </Button>
        <MarkForReview
          isForReview={Boolean(viewedDocument.properties?.isForReview)}
          onMarkForReview={() => handleMarkForReview(viewedDocument)}
        />
      </StyledActionsHeader>
      <StyledDocumentViewer
        title="document"
        src={viewedDocument.webViewLink}
      ></StyledDocumentViewer>
    </StyledContainer>
  );
};
