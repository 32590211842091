import { useEffect } from 'react';

import {
  persistCurrentProgramId,
  selectCurrentUserClassroomPrograms,
  selectCurrentUserProgramId,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
} from '@xq/web-components';

import { NavSelect } from '../NavSelect/NavSelect';

export const NavProgramSelect = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const programs = useAppSelector(selectCurrentUserClassroomPrograms);
  const selectedProgramId = useAppSelector(selectCurrentUserProgramId);

  const handleChange = (value: string) => {
    if (!currentUser.currentClassroomId) {
      return;
    }

    dispatch(
      persistCurrentProgramId({
        userId: currentUser.uid,
        classroomId: currentUser.currentClassroomId,
        programId: value,
      })
    );
  };

  useEffect(() => {
    const programExists = programs.find((p) => p.id === selectedProgramId);

    if (!programExists && programs.length && currentUser.currentClassroomId) {
      dispatch(
        persistCurrentProgramId({
          userId: currentUser.uid,
          classroomId: currentUser.currentClassroomId,
          programId: programs[0].id,
        })
      );
    }
  }, [selectedProgramId, programs, currentUser.currentClassroomId]);

  return (
    <NavSelect
      value={selectedProgramId ?? ''}
      onChange={handleChange}
      options={programs.map((p) => ({ id: p.id, label: p.title }))}
    />
  );
};
