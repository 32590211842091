import { styled, Typography, useMediaQuery } from '@mui/material';
import {
  persistCurrentClassroomId,
  selectCurrentUserClassrooms,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
  MOBILE_MEDIA_QUERY,
} from '@xq/web-components';

import { NavSelect } from '../NavSelect/NavSelect';

const StyledSingleRoom = styled(Typography)`
  color: var(--white);
  padding: 10px;
  font-size: 1rem;
`;

export const NavClassroomSelect = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUserState);
  const classrooms = useAppSelector(selectCurrentUserClassrooms);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const handleChange = (value: string) => {
    dispatch(
      persistCurrentClassroomId({
        userId: currentUser.uid,
        currentClassroomId: value,
      })
    );
  };

  return classrooms?.length > 1 ? (
    <NavSelect
      value={currentUser.currentClassroomId ?? ''}
      onChange={handleChange}
      options={classrooms.map((c) => ({ id: c.id, label: c.name }))}
    />
  ) : (
    <StyledSingleRoom>{isMobile ? classrooms?.[0]?.name : ''}</StyledSingleRoom>
  );
};
