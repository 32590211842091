import { SelectChangeEvent } from '@mui/material';

import styles from './nav-select.module.scss';
import { StyledSelect, StyledMenuItem } from './NavSelect.styles';

type Option = {
  id: string;
  label: string;
};

type NavSelectProps = {
  value: string;
  onChange: (value: string) => void;
  options: Option[];
};

export const NavSelect = ({ onChange, value, options }: NavSelectProps) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <StyledSelect
      variant="outlined"
      value={value}
      onChange={handleChange}
      MenuProps={{
        classes: { paper: styles.menuContainer },
      }}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.id} value={option.id}>
          {option.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};
