import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { STUDENT_APP_ROUTES } from '@xq/web-config';
import { useStudentWizardGuide } from '../hooks';
import ADD_POST_OR_FILE_IMAGE from '../images/add_post_or_file.png';
import COMPLETING_PHASE_IMAGE from '../images/completing_phase.png';
import EXPLORE_PHASES_IMAGE from '../images/explore_phases.png';
import MAIN_MENU_IMAGE from '../images/main_menu.png';
import WRITING_POST_IMAGE from '../images/writing_post.png';
import {
  StyledButtonContainer,
  StyledCloseButton,
  StyledTextContent,
  StyledContentContainer,
} from './MobileWizardGuide.styles';

const MOBILE_WIZARD_GUIDE_IMAGES = [
  MAIN_MENU_IMAGE,
  EXPLORE_PHASES_IMAGE,
  COMPLETING_PHASE_IMAGE,
  ADD_POST_OR_FILE_IMAGE,
  WRITING_POST_IMAGE,
];

export const MobileWizardGuide = () => {
  const { steps } = useStudentWizardGuide();

  const [currentStep, setCurrentStep] = useState(0);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isLastStep = currentStep + 1 >= steps.length;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ position: 'relative' }}>
        <StyledCloseButton
          onClick={() => {
            navigate(STUDENT_APP_ROUTES.getJournalPhase('0', '0', '-1', '-1'));
          }}
        >
          <CloseIcon />
        </StyledCloseButton>
        <img
          src={MOBILE_WIZARD_GUIDE_IMAGES[currentStep]}
          style={{ width: '100%' }}
          alt=""
        />
      </div>
      <StyledContentContainer>
        <StyledTextContent
          dangerouslySetInnerHTML={{
            __html: steps?.[currentStep]?.content,
          }}
        ></StyledTextContent>
        <StyledButtonContainer>
          <Button
            disabled={!currentStep}
            variant="outlined"
            color="inherit"
            onClick={() => {
              setCurrentStep((s) => s - 1);
            }}
          >
            {t('back')}
          </Button>
          <Typography fontSize={12}>
            {' '}
            {t('step')} {currentStep + 1} {t('of')} {steps?.length}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              if (isLastStep) {
                navigate(
                  STUDENT_APP_ROUTES.getJournalPhase('0', '0', '-1', '-1')
                );
                return;
              }

              setCurrentStep((s) => s + 1);
            }}
          >
            {isLastStep ? t('finish') : t('next')}
          </Button>
        </StyledButtonContainer>
      </StyledContentContainer>
    </div>
  );
};
