import { Avatar, Badge, IconButton, styled } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge/Badge';

export const StyledActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: var(--top-bar-height);
`;

export const StyledBadge = styled((props: BadgeProps) => (
  <Badge classes={{ badge: 'badge' }} {...props} />
))`
  padding: 12px;

  .badge {
    top: 15px;
    right: 10px;
    height: 16px;
    font-size: 9px;
    min-width: 16px;
    border-radius: 4px;
    padding: 0 2px;
  }
`;

export const StyledUserAvatar = styled(IconButton)(() => ({
  marginRight: '5px',
}));

export const StyledNotificationIconWrapper = styled(IconButton)`
  border-radius: 0;
  cursor: pointer;
`;

export const StyledImage = styled(Avatar)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;
