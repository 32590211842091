import { PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { isEmpty } from 'lodash';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import {
  useAppSelector,
  MOBILE_MEDIA_QUERY,
  LoaderLogo,
  useFirebaseAuthStudentUser,
  selectIsImpersonating,
  ErrorPage,
  selectIsLoadingClassrooms,
  selectCurrentUserState,
} from '@xq/web-components';

import { getAuthorisedRoutes, getUnAuthorisedRoutes } from './routes';

export const AppRouteProvider = ({ children }: PropsWithChildren) => {
  const { userAuthState, setUserAuthState, authUser } =
    useFirebaseAuthStudentUser();
  const isImpersonating = useAppSelector(selectIsImpersonating);
  const currentUser = useAppSelector(selectCurrentUserState);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isLoadingClassrooms = useAppSelector(selectIsLoadingClassrooms);
  const isLoading =
    isEmpty(currentUser) ||
    (!currentUser?.roles?.length && currentUser?.classroomIds?.length) ||
    isLoadingClassrooms;

  const getRoutes = useCallback(() => {
    if (userAuthState === 'loggedOut') {
      return getUnAuthorisedRoutes(
        isImpersonating,
        userAuthState,
        setUserAuthState,
        authUser
      );
    }

    return getAuthorisedRoutes(isMobile, userAuthState, authUser);
  }, [isMobile, userAuthState, isImpersonating, authUser]);

  if (
    userAuthState === 'loading' ||
    (isLoading && userAuthState !== 'loggedOut')
  ) {
    return <LoaderLogo />;
  }

  return (
    <ErrorBoundary
      fallback={(e) => <ErrorPage error={e.error} isTeacherApp={false} />}
    >
      <RouterProvider router={createBrowserRouter(getRoutes())} />
    </ErrorBoundary>
  );
};
