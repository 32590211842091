import { ElementType, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, SvgIcon, Tab, Tabs, useMediaQuery } from '@mui/material';
import { isNil } from 'lodash';

import styles from './navigation-tabs.module.scss';
import { PathLabel } from '../interface/path-label';
import { MOBILE_MEDIA_QUERY } from '../utils';

export type NavigationTabsProps = {
  icons: ElementType[];
  routes: PathLabel[];
};

export function NavigationTabs({
  routes,
  icons,
}: NavigationTabsProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<number | boolean>(0);

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const onTabChange = (event: unknown, tabIndex: number) => {
    const route = routes[tabIndex];
    setCurrentTab(tabIndex);
    navigate(route.path);
  };

  useEffect(() => {
    const tabIndex = routes.findIndex((r) =>
      location.pathname.includes(r.path)
    );

    const isOnAnotherPage = tabIndex === -1;

    if (!isNil(tabIndex) && tabIndex !== currentTab) {
      setCurrentTab(isOnAnotherPage ? false : tabIndex);
    }
  }, [location.pathname, isMobile]);

  const renderTabIcon = (pathLabel: PathLabel, index: number) => {
    return <SvgIcon component={icons[index]} />;
  };

  return (
    <Tabs
      onChange={onTabChange}
      value={currentTab}
      indicatorColor="primary"
      textColor="primary"
      TabIndicatorProps={{ style: { height: 5 } }}
    >
      {routes.map((pathLabel, index) => (
        <Tab
          data-cy={`${pathLabel.label}_tab`}
          tabIndex={0}
          key={index}
          label={t(`${pathLabel.label}`) || pathLabel.label}
          icon={renderTabIcon(pathLabel, index)}
          className={styles.tab}
          classes={{ root: styles.tabRoot }}
        />
      ))}
    </Tabs>
  );
}
