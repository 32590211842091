import { lazy } from 'react';
import i18n from 'i18next';
import { STUDENT_APP_ROUTES } from '@xq/web-config';
import { User } from '@firebase/auth';
import {
  DocumentViewer,
  ErrorPage,
  FbAuthUserState,
  JournalPhase,
  Login,
  MobileWizardGuide,
} from '@xq/web-components';
import { Navigate, Outlet } from 'react-router-dom';
import { ReactComponent as logo } from '../assets/tab-icons/logo-no-sub-student.svg';
import loginImgSrc from '../assets/student-img.webp';
import loginImgFallbackSrc from '../assets/student-img.png';
import App from './App';
import PhasesAndJournal from './Screens/PhasesAndJournal/PhasesAndJournal';
import { Errors, FeedSlugs, UserRoles } from '@xq/domain';
import { ClassroomRepository } from '@xq/infrastructure';

const TermsOfService = lazy(
  () => import('./Screens/TermsOfService/TermsOfService')
);
const PrivacyPolicy = lazy(
  () => import('./Screens/PrivacyPolicy/PrivacyPolicy')
);
const YourClassrooms = lazy(
  () => import('./Screens/YourClassrooms/YourClassrooms')
);
const PostContent = lazy(() => import('./Screens/PostContent/PostContent'));
const JournalFeedPage = lazy(
  () => import('./Screens/JournalFeedPage/JournalFeedPage')
);
const StudentFilesPage = lazy(
  () => import('./Screens/StudentFilesPage/StudentFilesPage')
);
const MobileAddFiles = lazy(
  () => import('./Screens/MobileAddFiles/MobileAddFiles')
);
const MobileFeedScreen = lazy(
  () => import('./Screens/MobileFeedScreen/MobileFeedScreen')
);
const MobileJournalFeedComments = lazy(
  () => import('./Screens/MobileJournalFeedComments/MobileJournalFeedComments')
);

const PRIVACY_POLICY_URL = process.env.NX_PUBLIC_PRIVACY_POLICY_URL || '';
const TERMS_OF_SERVICE_URL = process.env.NX_PUBLIC_TERMS_OF_SERVICE_URL || '';

const handleErrors = async (authUser: User) => {
  const { claims } = await authUser.getIdTokenResult();
  const classroomRepo = new ClassroomRepository();
  const roles = claims?.roles as UserRoles[] || [];
  const hasWrongRole =
    roles.includes(UserRoles.teacher) ||
    roles.includes(UserRoles.admin) ||
    roles.includes(UserRoles.viewer);
  const hasRooms = await classroomRepo.checkUserRoleByFieldPath(
    authUser.email,
    'participantEmails'
  );
  const hasValidRooms = await classroomRepo.checkForValidStudentClassrooms(
    authUser.email as string
  );

  if (hasWrongRole) {
    throw new Error(Errors.userWrongRole);
  }

  if (!hasRooms) {
    throw new Error(Errors.accountHasNoRooms);
  }

  if (!hasValidRooms) {
    throw new Error(Errors.classroomHasNoFolder);
  }
};

export const getUnAuthorisedRoutes = (
  isImpersonating: boolean,
  userAuthState: FbAuthUserState,
  setUserAuthState: (userAuthState: FbAuthUserState) => void,
  authUser: User | null
) => {
  return [
    {
      path: STUDENT_APP_ROUTES.getRoot(),
      element: <Outlet />,
      errorElement: <ErrorPage isTeacherApp={false} />,
      children: [
        {
          path: STUDENT_APP_ROUTES.getRoot(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp={false}
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperStudent"
              impersonate={isImpersonating}
            />
          ),
          loader: async () => {
            if (userAuthState !== 'loggedOut' && authUser) {
              await handleErrors(authUser);
            }
            return null;
          },
        },
        {
          path: STUDENT_APP_ROUTES.getLogin(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp={false}
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperStudent"
            />
          ),
          loader: async () => {
            if (userAuthState !== 'loggedOut' && authUser) {
              await handleErrors(authUser);
            }
            return null;
          },
        },
        {
          path: STUDENT_APP_ROUTES.getDemoLogin(),
          element: (
            <Login
              userAuthState={userAuthState}
              setUserAuthState={setUserAuthState}
              isTeacherApp={false}
              logo={logo}
              imageSrc={loginImgSrc}
              imageFallbackSrc={loginImgFallbackSrc}
              colorTheme="loginWrapperStudent"
              impersonate
            />
          ),
          errorElement: <ErrorPage isTeacherApp={false} />,
        },
        {
          path: STUDENT_APP_ROUTES.getTermsOfService(),
          element: <TermsOfService url={TERMS_OF_SERVICE_URL} />,
        },
        {
          path: STUDENT_APP_ROUTES.getPrivacyPolicy(),
          element: <PrivacyPolicy url={PRIVACY_POLICY_URL} />,
        },
        {
          path: STUDENT_APP_ROUTES.getErrorScreen(),
          element: <ErrorPage isTeacherApp={false} />,
        },
        {
          path: '*',
          element: (
            <Navigate
              to={
                isImpersonating
                  ? STUDENT_APP_ROUTES.getDemoLogin()
                  : STUDENT_APP_ROUTES.getLogin()
              }
              replace
            />
          ),
        },
      ],
    },
  ];
};

const mobileRoutes = (isMobile: boolean) => {
  return isMobile
    ? [
        {
          path: STUDENT_APP_ROUTES.getJournalFeed(),
          element: <JournalFeedPage />,
        },
        {
          path: STUDENT_APP_ROUTES.getStudentFiles(),
          element: <StudentFilesPage />,
        },
      ]
    : [];
};

export const getAuthorisedRoutes = (
  isMobile: boolean,
  userAuthState: FbAuthUserState,
  authUser: User | null
) => {
  return [
    {
      path: STUDENT_APP_ROUTES.getRoot(),
      element: <App />,
      errorElement: <ErrorPage isTeacherApp={false} />,
      loader: async () => {
        if (authUser) {
          await handleErrors(authUser);
        }
        return null;
      },
      children: [
        {
          path: STUDENT_APP_ROUTES.getJournalPath(),
          element: <PhasesAndJournal renderOnlyPhases={isMobile} />,
          children: [
            {
              path: STUDENT_APP_ROUTES.getJournalPhase(),
              element: <JournalPhase />,
            },
          ],
        },
        {
          path: STUDENT_APP_ROUTES.getYourClassrooms(),
          element: <YourClassrooms />,
        },
        {
          path: STUDENT_APP_ROUTES.getResourceContent(),
          element: <PostContent />,
        },
        {
          path: STUDENT_APP_ROUTES.getAddFiles(),
          element: <MobileAddFiles />,
        },
        {
          path: STUDENT_APP_ROUTES.getMobileWizardGuide(),
          element: <MobileWizardGuide />,
        },
        {
          path: STUDENT_APP_ROUTES.getJournalFeedComments(),
          element: <MobileJournalFeedComments />,
        },
        {
          path: STUDENT_APP_ROUTES.getPostToJournal(),
          element: (
            <MobileFeedScreen
              feedSlug={FeedSlugs.student}
              headerName={i18n.t('post_to_journal')}
            />
          ),
        },
        {
          path: STUDENT_APP_ROUTES.getDocumentViewer(),
          element: <DocumentViewer />,
        },
        {
          path: '*',
          element: (
            <Navigate to={STUDENT_APP_ROUTES.getJournalPath()} replace />
          ),
        },
        {
          path: STUDENT_APP_ROUTES.getTermsOfService(),
          element: <TermsOfService url={TERMS_OF_SERVICE_URL} />,
        },
        {
          path: STUDENT_APP_ROUTES.getPrivacyPolicy(),
          element: <PrivacyPolicy url={PRIVACY_POLICY_URL} />,
        },
        ...mobileRoutes(isMobile),
      ],
    },
  ];
};
