import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type FormMultiselectProps = {
  label: string;
  selectables: string[];
  selected: string[];
  handleSelect: (selected: string[]) => void;
  handleDelete: (selectableForDelete: string) => void;
};

export const FormMultiselect = ({
  selectables,
  label,
  handleSelect,
  selected,
  handleDelete,
}: FormMultiselectProps) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    handleSelect(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div style={{ width: '100%' }}>
      <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  deleteIcon={
                    <CloseIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={() => {
                    handleDelete(value);
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {selectables.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selected, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
