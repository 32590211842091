import 'swiper/css';
import { useMemo, StrictMode, Suspense } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

import {
  ApiProvider,
  calculateHeight,
  store,
  XqTheme,
  initGoogleAPIs,
  initSentry,
  LoaderLogo,
  closeSnackbar,
  useAppDispatch,
  SnackbarVariant,
  NotificationsProvider,
  ModalProvider,
  NotifierContextProvider,
  MobileProvider,
} from '@xq/web-components';
import { initializeI18n } from '@xq/web-config';

import { AppRouteProvider } from './app/AppRouteProvider';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';

const NX_GOOGLE_CLIENT_ID = process.env.NX_PUBLIC_GOOGLE_CLIENT_ID || '';

initGoogleAPIs();
initSentry(process.env.NX_PUBLIC_SENTRY_DSN_STUDENT || '');
initializeI18n(process.env['NX_PUBLIC_NODE_ENV'] === 'development');

const RootComponent = () => {
  const MaterialTheme = useMemo(() => XqTheme, []);
  const dispatch = useAppDispatch();

  const onCloseSnackbar = (key: string) => {
    dispatch(closeSnackbar(key));
  };

  const closeSnackbarAction = (key: SnackbarKey) => (
    <CloseIcon
      cursor="pointer"
      onClick={() => {
        onCloseSnackbar(key.toString());
      }}
    />
  );

  return (
    <GoogleOAuthProvider clientId={NX_GOOGLE_CLIENT_ID}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={MaterialTheme}>
          <CssBaseline />
          <ApiProvider>
            <NotificationsProvider>
              <ModalProvider>
                <NotifierContextProvider>
                  <MobileProvider>
                    <SnackbarProvider
                      maxSnack={5}
                      iconVariant={{
                        [SnackbarVariant.SUCCESS]: (
                          <SuccessIcon data-cy="snackbar" />
                        ),
                        [SnackbarVariant.ERROR]: <ErrorIcon />,
                      }}
                      action={closeSnackbarAction}
                      classes={{
                        variantSuccess: 'successSnackbarBody',
                        variantError: 'errorSnackbarBody',
                      }}
                    >
                      <AppRouteProvider />
                    </SnackbarProvider>
                  </MobileProvider>
                </NotifierContextProvider>
              </ModalProvider>
            </NotificationsProvider>
          </ApiProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

calculateHeight();

root.render(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<LoaderLogo />}>
        <RootComponent />
      </Suspense>
    </Provider>
  </StrictMode>
);
