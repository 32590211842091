import { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import {
  EventTypes,
  PathLabel,
  persistCurrentProgramId,
  selectCurrentUserClassroomPrograms,
  selectCurrentUserProgramId,
  selectCurrentUserState,
  useAppDispatch,
  useAppSelector,
  useMobile,
  XqIconPrimaryColor,
} from '@xq/web-components';
import { STUDENT_APP_ROUTES } from '@xq/web-config';

import styles from './mobile-header.module.scss';
import { NavClassroomSelect } from '../NavClassroomSelect/NavClassroomSelect';
import { NavProgramSelect } from '../NavProgramSelect/NavProgramSelect';
import { HeaderActions } from '../HeaderActions/HeaderActions';

const NAV_LIST: PathLabel[] = [
  {
    label: 'resources',
    path: STUDENT_APP_ROUTES.getJournalPath(),
  },
  {
    label: 'project_journal',
    path: STUDENT_APP_ROUTES.getJournalFeed(),
  },
  {
    label: 'my_files',
    path: STUDENT_APP_ROUTES.getStudentFiles(),
  }
];

export const MobileHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { headerContent, onlyHeaderContent } = useMobile();

  const programs = useAppSelector(selectCurrentUserClassroomPrograms);
  const selectedProgramId = useAppSelector(selectCurrentUserProgramId);
  const currentUser = useAppSelector(selectCurrentUserState);

  const dispatch = useAppDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === EventTypes.keyDown &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpenDrawer(open);
    };

  const isRouteActive = (path: string) => {
    return location.pathname.includes(path);
  };

  useEffect(() => {
    const programExists = programs.find((p) => p.id === selectedProgramId);

    if (!programExists && programs.length && currentUser.currentClassroomId) {
      dispatch(
        persistCurrentProgramId({
          userId: currentUser.uid,
          classroomId: currentUser.currentClassroomId,
          programId: programs[0].id,
        })
      );
    }
  }, [selectedProgramId, programs, currentUser.currentClassroomId]);

  return (
    <>
      <div className={styles.mobileHeader}>
        {!onlyHeaderContent && (
          <IconButton color="info" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        )}

        <h1 className={styles.header}>{headerContent}</h1>

        {!onlyHeaderContent && <HeaderActions />}
      </div>

      <Drawer
        classes={{
          paper: styles.drawer,
        }}
        anchor="top"
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        <div className={styles.drawerHeader}>
          <IconButton color="primary" onClick={toggleDrawer(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
          <XqIconPrimaryColor fontSize={'large'} color={'primary'} />
        </div>
        <Typography color="primary" className={styles.title}>
          {t('classroom')}
        </Typography>
        <NavClassroomSelect />

        <Typography color="primary" className={styles.title}>
          {t('module')}
        </Typography>
        <NavProgramSelect />

        <List>
          {NAV_LIST.map((item, index) => {
            return (
              <ListItemButton
                key={index}
                classes={{
                  selected: styles.selectedListItem,
                }}
                onClick={() => {
                  navigate(item.path);
                  setOpenDrawer(false);
                }}
                selected={isRouteActive(item.path)}
              >
                <ListItemText primary={t(item.label)} />
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};
